import {
  Authenticated,
  Unauthenticated,
  Authorized,
} from './Authenticated'
import { NavBar } from './NavBar'
import { NavDrawer } from './NavDrawer'
import { ContentDrawer } from './ContentDrawer'
import { HelpDialog, HelpDialogOption } from './HelpDialog'
import { LoginForm } from './LoginForm'
import { RegistrationForm } from './RegistrationForm'
import { ResetPasswordForm, ForgotPasswordForm } from './ResetPasswordForm'
import { Routes } from './Routes'
import { ManageProfile, ChangePasswordForm, UpdateProfileForm } from './ManageProfile'
import { Documents, DocumentsList, UploadDocumentForm, UploadDocumentDialog } from './Documents'

export {
  Authenticated,
  Unauthenticated,
  Authorized,
  NavBar,
  NavDrawer,
  ContentDrawer,
  HelpDialog,
  HelpDialogOption,
  LoginForm,
  RegistrationForm,
  ResetPasswordForm,
  ForgotPasswordForm,
  Routes,
  ManageProfile,
  ChangePasswordForm,
  UpdateProfileForm,
  Documents,
  DocumentsList,
  UploadDocumentForm,
  UploadDocumentDialog,
}

export default {
  Authenticated,
  Unauthenticated,
  Authorized,
  NavBar,
  NavDrawer,
  ContentDrawer,
  HelpDialog,
  HelpDialogOption,
  LoginForm,
  RegistrationForm,
  ResetPasswordForm,
  ForgotPasswordForm,
  Routes,
  ManageProfile,
  ChangePasswordForm,
  UpdateProfileForm,
  Documents,
  DocumentsList,
  UploadDocumentForm,
  UploadDocumentDialog,
}
