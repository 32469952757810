import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Alert, Box, Form, FormTextField, FormSubmitButton, Link } from 'components'
import { useLoginForm } from 'hooks'

export const LoginForm = () => {
  const [form, { loading, errors }] = useLoginForm()

  return (
    <Form form={form}>
      <FormTextField
        id="email"
        name="email"
        label="Email address"
        autoFocus={true}
        autoComplete="username"
      />

      <FormTextField
        id="password"
        name="password"
        label="Password"
        type="password"
      />

      { !loading && <>{ errors.map((error) => <Alert key={`${error.code}:${error.message}`} severity="error" sx={{ width: '100%', mb: 2 }}>
        {error.message}
      </Alert>)}</> }

      <FormSubmitButton
        loading={loading}
      >
        Log in
      </FormSubmitButton>

      <Box pt={2} textAlign="center">
        <Link component={RouterLink} to="/users/password/new">Forgot password?</Link>
      </Box>
    </Form>
  )
}

export default LoginForm
