import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Alert, Box, Button, Form, FormTextField, FormSubmitButton, Text } from 'components'
import { useResetPasswordForm } from 'hooks'

export const ResetPasswordForm = () => {
  const [form, { loading, submitted, errors }] = useResetPasswordForm()

  if (submitted) {
    return <Box>
      <Box pt={4}>
        <Text>Your password was changed successfully. Use the link below to login with your new password.</Text>
      </Box>

      <Box pt={2}>
        <Button component={RouterLink} to="/login">Back to login</Button>
      </Box>
    </Box>
  } else {
    return <Form form={form}>
      <FormTextField
        id="password"
        name="password"
        label="Password"
        autoFocus={true}
        type="password"
      />

      <FormTextField
        id="passwordConfirmation"
        name="passwordConfirmation"
        label="Confirm Password"
        type="password"
      />

      { !loading && <>{ errors.map((error) => <Alert key={`${error.code}:${error.message}`} severity="error" sx={{ width: '100%', mb: 2 }}>
        {error.message}
      </Alert>)}</> }

      <FormSubmitButton
        loading={loading}
      >
        Change my password
      </FormSubmitButton>
    </Form>
  }
}

export default ResetPasswordForm
