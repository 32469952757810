import { useCallback, useEffect, useState } from 'react'
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { useToken } from './useToken'
import { ClientError } from 'graphql/schema/graphql'

const client: AxiosInstance = axios.create({
  timeout: 30000,
  headers: { 'Accept': 'application/json' }
})

export type AxiosResponseWithErrors<RData, RVariables, Config = AxiosRequestConfig<RVariables>> = AxiosResponse<RData, Config> & {
  data: AxiosResponse<RData>['data'] & {
    errors?: ClientError[]
  }
}

export type AxiosErrorWithErrors<RData, RVariables> = AxiosError<RData, RVariables> & {
  response?: AxiosResponseWithErrors<RData, RVariables>
}

export type UseAxiosConfig<RData, RVariables, Config = AxiosRequestConfig<RVariables>> = AxiosRequestConfig<Config> & {
  lazy?: boolean
  onSuccess?: (response: AxiosResponseWithErrors<RData, Config>) => void
  onErrors?: (errors: ClientError[]) => void
}

export const useAxios = <RData, RVariables, Config = AxiosRequestConfig<RVariables>>({ lazy=false, onSuccess, onErrors, ...requestConfig }: UseAxiosConfig<RData, RVariables, Config>) => {
  const { token } = useToken()
  const [data, setData] = useState<RData>()
  const [errors, setErrors] = useState<ClientError[]>([])
  const [called, setCalled] = useState(false)
  const [loading, setLoading] = useState(false)

  const fetch = useCallback((reqCfg?: AxiosRequestConfig<Config>) => {
    const config = { ...requestConfig, ...reqCfg }
    if (token) {
      config.headers = { ...config.headers, 'Authorization': `Bearer ${token}`}
    }

    setData(null)
    setErrors([])
    setCalled(true)
    setLoading(true)

    return client(config).then((resp) => {
      setData(resp.data)
      if (resp.data?.errors?.length > 0) {
        setErrors(resp.data.errors)
        if (onErrors) onErrors(resp.data?.errors)
      } else {
        if (onSuccess) onSuccess(resp.data)
      }

      return resp
    }).catch((err: AxiosErrorWithErrors<RData, RVariables>) => {
      if (err.response?.data?.errors) {
        setErrors(err.response?.data?.errors)
        if (onErrors) onErrors(err.response?.data?.errors)
      } else {
        setErrors([{ message: err.message }])
        if (onErrors) onErrors([{ message: err.message }])
      }
    }).finally(() => {
      setLoading(false)
    })
  }, [ requestConfig, token, setData, setErrors, setLoading, onSuccess, onErrors ])

  useEffect(() => {
    if (!lazy) fetch(requestConfig)
  }, [ lazy, requestConfig, fetch ])

  return { data, errors, called, loading, fetch }
}

export default useAxios
