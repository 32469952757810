import React from 'react'
import MuiTypography, { TypographyProps } from '@mui/material/Typography'

declare module '@mui/material/Typography' {
  interface TypographyPropsColorOverrides {
    plain: true
    branding: true
    campus: true
    kitchen: true
    kiosk: true
    luxo: true
    market: true
    tools: true
  }
}

export const Text = <C extends React.ElementType='span'>(props: TypographyProps<C, { component?: C }>) => <MuiTypography {...props} />

export default Text
